import { navigate } from 'gatsby'

export const checkLogin = function () {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('agentId') != null
  }
  return false
}

export const removeLogin = function () {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('agentId')
    navigate('/login/')
  }
}
