import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import * as style from './ErrorToast.module.css'
import useToast from '../hooks/useToast'

const ErrorToast = () => {
  const { toast, closeToast, removeToastMessage } = useToast()
  const { show, message } = toast
  const [shouldRender, setRender] = useState(show)

  useEffect(() => {
    if (show) setRender(true)
  }, [show])

  const onAnimationEnd = () => {
    if (!show) {
      setRender(false)
      removeToastMessage()
    }
  }

  return (
    shouldRender && (
      <div
        className={clsx('animate__animated', show ? 'animate__fadeIn' : 'animate__fadeOut', style.container)}
        onAnimationEnd={onAnimationEnd}
      >
        <div className={style.alertIcon}>
          <svg className={style.iconRoot} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
          </svg>
        </div>
        <p className={style.message}>{message}</p>
        <div className={style.actionIcon}>
          <button className={style.ripple} onClick={closeToast}>
            <span className={style.actionIconLabel}>
              <svg className={style.iconRoot} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              </svg>
            </span>
            <span className={style.actionIconRipple} />
          </button>
        </div>
      </div>
    )
  )
}

export default ErrorToast
