import React from 'react'
import * as style from './InputText.module.css'
import PropTypes from 'prop-types'

const PlainInput = ({ inputProps, label, name }) => {
  const RequiredStar = () => (inputProps.required ? <span style={{ color: 'red' }}>*</span> : null)
  return (
    <div className={style.inputTextContainer}>
      <label className={style.inputTextLabel} htmlFor={name}>
        {label} <RequiredStar/>
      </label>
      <input className={style.inputText} type='text' {...inputProps} name={name}/>
    </div>
  )
}

PlainInput.propTypes = {
  inputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string
}

PlainInput.defaultProps = {
  inputProps: {},
  onChange: () => {
  },
  value: ''
}

export default PlainInput
