import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as layoutStyle from './Layout.module.css'

const Button = ({ children, innerProps = {}, onClick, type = 'button' }) => {
  return (
      <button
        className={clsx(layoutStyle.button, layoutStyle.ripple)}
        onClick={onClick}
        type={type}
        {...innerProps}
      >
        {children}
      </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string
}

export default Button
