import React from 'react'
import PropTypes from 'prop-types'
import * as style from './Layout.module.css'
import logo from '../images/logo.png'

const Layout = ({ children }) => (
  <>
    <header className={style.header}>
      <div className={style.redBlock} />
      <nav>
        <img alt="logo de la compañía" src={logo} />
      </nav>
    </header>
    <main className={style.main}>{children}</main>
    <footer className={style.footer}>
      <div />
    </footer>
  </>
)

Layout.propTypes = {
  children: PropTypes.any
}

export default Layout
