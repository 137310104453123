export class Call {
  constructor () {
    this.running = false
    this.tries = 45
    this.interval = 2000
    this.timeout = undefined
  }

  poll ({ body, config }, setToast) {
    const form = { body, config }

    if (!this.running) {
      this.running = true
      return this._poll(form, setToast)
    }
  }

  cancel () {
    this.running = false
    clearTimeout(this.timeout)
  }

  async _poll (form, setToast) {
    let attempts = 0

    const executePoll = async () => {
      try {
        const result = await this._call(form)
        this.running = false
        return result
      } catch {
        attempts++

        if (attempts >= this.tries) {
          this.running = false
          throw new Error('Exceeded max attempts')
        } else {
          setToast({
            show: true,
            message: 'Ahora debe transferir la llamada, por favor.'
          })
          await this._sleep()
          return executePoll()
        }
      }
    }

    return executePoll()
  }

  async _call ({ body, config }) {
    const endpoint = process.env.GATSBY_API_URL
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      config
    }

    const response = await fetch(endpoint, requestOptions)

    if (response.ok) {
      return response
    } else {
      throw response
    }
  }

  async _sleep () {
    return new Promise((resolve) => {
      this.timeout = setTimeout(resolve, this.interval)
    })
  }
}
