import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

import * as style from './InputText.module.css'
import { getTelephones } from '../services/telephones'

const TelephoneInput = ({ inputProps, label, name }) => {
  const RequiredStar = () => (inputProps.required ? <span style={{ color: 'red' }}>*</span> : null)
  const [telephones, setTelephones] = useState([])

  useEffect(() => {
    getTelephones().then(setTelephones)
  }, [])

  return (
    <div className={style.inputTextContainer}>
      <label className={style.inputTextLabel} htmlFor={name}>
        {label} <RequiredStar />
      </label>
      <Autocomplete
        required={true}
        noOptionsText='Este telefono no esta disponible. REALIZAR TRANSFERENCIA MANUAL.'
        classes={{
          inputRoot: style.inputText
        }}
        options={telephones}
        renderInput={(params) => (<TextField {...params} required name={name} {...inputProps} />)}
        getOptionLabel={(option) => `${option.phone} - ${option.name}`}
      />
    </div>
  )
}

TelephoneInput.propTypes = {
  inputProps: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string
}

export default TelephoneInput
