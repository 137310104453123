import Layout from '../components/Layout'
import React, { useState } from 'react'

import ContactForm from '../components/ContactForm'
import { getAgentId } from '../services/agentId'
import { Call } from '../services/calls'
import useToast from '../hooks/useToast'

const ContactFormPage = () => {
  const SEND_LABEL = 'Enviar'
  const CANCEL_LABEL = 'Cancelar'
  const [call, setCall] = useState(new Call())
  const [submitLabel, setSubmitLabel] = useState(SEND_LABEL)
  const { closeToast, setToast } = useToast()

  const handleSubmit = async (event) => {
    event.preventDefault()
    const elements = new FormData(event.target)
    const agentId = getAgentId()
    const entries = Object.fromEntries(elements)
    const formJson = { agentId, ...entries, edaNumber: entries.edaNumber.split('-')[0].trim() }

    const pollEnded = () => {
      closeToast()
      setSubmitLabel(SEND_LABEL)
      setCall(new Call())
    }

    if (call.running) {
      call.cancel()
      return pollEnded()
    }

    try {
      setSubmitLabel(CANCEL_LABEL)
      await call.poll({ body: formJson }, setToast)
    } catch (e) {
      console.error(e)
      // setToast({
      //   show: true,
      //   message: 'Error: No se ha encontrado ninguna llamada en espera con su login.'
      // })
    } finally {
      event.target.reset()
      pollEnded()
    }
  }

  return (
    <Layout>
      <ContactForm onSubmit={handleSubmit} submitLabel={submitLabel} />
    </Layout>
  )
}

export default ContactFormPage
