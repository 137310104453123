import React from 'react'
import PropTypes from 'prop-types'
import PlainInput from './PlainInput'
import TelephoneInput from './TelephoneInput'

const InputText = ({ inputProps, label, name, autocomplete }) => {
  if (autocomplete) {
    return (
      <TelephoneInput key={name} inputProps={inputProps} label={label} name={name} />
    )
  } else {
    return (
      <PlainInput key={name} inputProps={inputProps} label={label} name={name}/>
    )
  }
}

InputText.propTypes = {
  inputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  autocomplete: PropTypes.bool
}

InputText.defaultProps = {
  inputProps: {},
  onChange: () => {
  },
  autocomplete: false
}

export default InputText
